import React from "react";
import { Container, Navbar } from "react-bootstrap";
import {Outlet} from "react-router-dom";

const Layout = () => {
  return (
    <>
      <Navbar bg="info" variant="dark">
        <Container fluid>
          <Navbar.Brand href="" style={{width:'120px'}}>
             <img
                alt=""
                src="/pti_logo.png"
                height="30"
                className="d-inline-block align-top"
                onClick={(e) => {}}
              />{' '}
              <b>PTI Health Payments</b>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default Layout;