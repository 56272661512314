import React from 'react';
import { Button, Card } from 'react-bootstrap';

function NoPage() {
    return (
    <div style={{padding:'10px', maxWidth:'600px'}}>
    <Card>
      <Card.Body>
        <Card.Title>404 Page Not Found</Card.Title>
        <Card.Text>
          You seem to be lost.
        </Card.Text>
        <Button variant="primary" href={window.location.origin} style={{width:'100%'}}>Go Home</Button>
      </Card.Body>
    </Card>
    </div>
    );
}

export default NoPage;