import React, { useState } from 'react';
import { Alert, Button, Card, Form, ListGroup, Spinner } from 'react-bootstrap';
import InvoiceItem from './InvoiceItem';

function Invoices({data, setClientSecret}:{data:any, setClientSecret: any}) {
    const [isSubmitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const [total, setTotal] = useState(getTotal());
    const [error, setError] = useState("");

    function submit(e: any) {
        if (!validate()) return;

        setSubmitted(true);
        getPaymentIntent();
    }

    function validate() {
        let a = false;
        if (/(^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$)/.test(email.trim())) a = true;
        if (email.trim().length === 0) a = true;

        setValidEmail(a);
        return a;
    }

    function getPaymentIntent() {
        fetch(window.location.origin + "/stripe/create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                amount: total,
                scheduleIDs: getScheduleIDs(),
                amountsPaid: getAmountsPaid(),
                email: email.trim(),
            }),
          })
            .then((res) => res.json())
            .then((data) => handleResponse(data))
            .catch(error => {
                console.log(error);
                setError("Sorry, an error occurred.  Please contact PTI Health for help.");
                setSubmitted(false);
        });
    }

    function getScheduleIDs() {
        let s = "";
        data.invoices.filter((invoice: any) => invoice.Pay === true).forEach((invoice: any) => {s = s + (s.length>0?';':'') + invoice.ScheduleID});
        return s;
    }

    function getAmountsPaid() {
        let s = "";
        data.invoices.filter((invoice: any) => invoice.Pay === true).forEach((invoice: any) => {s = s + (s.length>0?';':'') + invoice.Amount});
        return s;
    }

    function handleResponse(data : any) {
        let secret = data.clientSecret;
        if (secret.length === 0) setError("Sorry, an error occurred.  Please contact PTI Health for help."); else
        setClientSecret(secret, total)
        setSubmitted(false);
    }

    function handleKeyPress(e: any) {
        if(e.charCode===13){
          e.preventDefault();
          validate();
        } 
      }

    function updateTotal() {
        setTotal(getTotal());
    }

    function getTotal() {
        return data.invoices.filter((invoice: any) => (invoice.Pay === true)).map((invoice: any) => Number(invoice.Amount)).reduce((a: any, b: any) => a + b, 0).toFixed(2);
    }

    return (
        <Card>
        <Card.Header as="h5">Select Invoices to Pay</Card.Header>
        <Card.Body>
        <ListGroup variant="flush" as="h5">
        {data.invoices
        .map((invoice: any) => (
            <InvoiceItem key={invoice.ScheduleID} data={invoice} update={updateTotal} editable={!isSubmitted}/>
        ))}
        </ListGroup>
        <hr />
        <Card.Title>Total:&nbsp;&nbsp;<b>${total}</b></Card.Title>
        <br />
        <Form noValidate >
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="email" placeholder="Enter email (optional)" value={email} onKeyPress={handleKeyPress} onChange={e => setEmail(e.target.value)} isInvalid={!validEmail} autoFocus />
        <Form.Control.Feedback type="invalid">
        Please enter a valid email address.
      </Form.Control.Feedback>
        <Form.Text className="text-muted">
          Please provide an email address for a receipt.
        </Form.Text>
      </Form.Group>
      </Form>
        <Button variant="primary" disabled={isSubmitted || total === '0.00'} onClick={e => submit(e)}>{isSubmitted && <span><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>&nbsp;</span>}Next</Button>
        {error.length>0?<Alert variant='danger' style={{marginTop:'10px', marginBottom:'0px'}}>{error}</Alert>:null}
        </Card.Body>
      </Card>
        );
    }
    
    export default Invoices;
    