import React, { useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

function InvoiceItem({data, update, editable}: {data: any, update: any, editable: boolean}) {
    const [isChecked, setChecked] = useState(true);

    function onChange() {
        if (!editable) return;
        data.Pay = !data.Pay;
        setChecked(data.Pay);
        update();
    }

    return (
    <ListGroup.Item className="d-flex align-items-center" >
        <Form.Check type='checkbox' checked={isChecked} onChange={()=>{onChange()}} style={{width:'50px'}}/>
        <table><tbody><tr><td>Date:</td><td style={{paddingLeft:'20px'}}>{data.DrawDate}</td></tr><tr><td>Amount:</td><td style={{paddingLeft:'20px'}}>${data.Amount}</td></tr></tbody></table></ListGroup.Item>
    );
    }
    
    export default InvoiceItem;