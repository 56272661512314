import React, { useState } from 'react';
import Account from './Account';
import Invoices from './Invoices';
import Stripe from './Stripe';

function Cards() {
    const [invoices, setInvoices] = useState(null);
    const [amount, setAmount] = useState("0.00");
    const [clientSecret, setClientSecret] = useState(null);

    const handleInvoices = (data: any) => {
        setInvoices(data);
      };

      const handleSecret = (clientSecret: any, total: any) => {
        setAmount(total);
        setClientSecret(clientSecret);
      }
    
    return (
            <div style={{padding:'10px', maxWidth:'600px'}}>
            {invoices === null ? <Account handleInvoices={handleInvoices} /> : 
            clientSecret === null ? <Invoices data={invoices} setClientSecret={handleSecret} /> : <Stripe clientSecret={clientSecret} amount={amount} /> }
            </div>
        );
    }
    
    export default Cards;
    