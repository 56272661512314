import React, { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';

function Account({handleInvoices}:{handleInvoices: any}) {
    const [account, setAccount] = useState("");
    const [zip, setZip] = useState("");
    const [validAccount, setValidAccount] = useState(true);
    const [validZip, setValidZip] = useState(true);
    const [isSubmitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");

    function submit(e: any) {
        setError("");
        let a = false;
        let z = false;
        if (/(^\d{1,7}$)/.test(account.trim())) a = true;
        if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip.trim())) z = true;
        setValidAccount(a);
        setValidZip(z);
        if (a && z) {
            setSubmitted(true);
            fetchResults();
        }
    }

    function fetchResults() {
        fetch(window.location.origin + '/stripe/getInvoices', { method: 'POST', headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json' }, body: JSON.stringify( {
            account: account.trim(),
            zip: zip.trim(),
        })}).then(response => response.json())
        .then(data => {
            if (hasInvoices(data)) handleInvoices(data);
        }).catch(error => {
            console.log(error);
            setError("Sorry, an error occurred.  Please contact PTI Health for help.");
    });
    }

    function hasInvoices(data: any) {
        if (data.invoices.length > 0) return true;
        setError("No invoices were found.");
        setSubmitted(false);
        return false;
    }

    return (
        <Card>
        <Card.Header as="h5">Welcome to the PTI Health Patient Payment Portal </Card.Header>
        <Card.Body>
        <Card.Text>Please enter your PTI account number (found on the invoice you received) and your mailing zip code below.</Card.Text>
          <Form noValidate >
            <Form.Group className="mb-3" controlId="formAccountNo">
            <Form.Label>Account Number</Form.Label>
            <Form.Control value={account} onChange={e => setAccount(e.target.value)} isInvalid={!validAccount} autoFocus required />
            <Form.Control.Feedback type="invalid">Please enter your account number</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formZip">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control value={zip} onChange={e => setZip(e.target.value)} isInvalid={!validZip} required />
            <Form.Control.Feedback type="invalid">Please enter your 5 digit zip code</Form.Control.Feedback>
            </Form.Group>
          </Form>
          <br />
          <Button variant="primary" disabled={isSubmitted} onClick={e => submit(e)}>{isSubmitted && <span><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>&nbsp;</span>}Submit</Button>
          {error.length>0?<Alert variant='danger' style={{marginTop:'10px', marginBottom:'0px'}}>{error}</Alert>:null}
        </Card.Body>
      </Card>
        );
    }
    
    export default Account;
    