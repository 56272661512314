import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Card } from 'react-bootstrap';
import CheckoutForm from './CheckoutForm';

function Stripe({clientSecret, amount} : {clientSecret: string, amount: string}) {
    //const stripePromise = loadStripe("pk_test_51HbNTYBdoxQForvLp21UWzVhKEmmeNcYZHVsHAtvfQSsj60JiJthl5u9nvCC6DrHcFvywt7JFrpHSelQgLb31p4s00b62antaq");
    const stripePromise = loadStripe("pk_live_51HbNTYBdoxQForvLUNr19bZC4FvUWk6WVEDBlqpS0DBnyIGyoZBpEcicqdVlMryIZVGWAJBMGohI52ieSLxEZdzT00qjcobR2S");
    const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
      };

    return (
        <Card>
        <Card.Header as="h5">Enter Payment Details</Card.Header>
        <Card.Body>
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm amount={amount} />
        </Elements>
        </Card.Body>
      </Card>
    )

}

export default Stripe;
    