import React from 'react';
import { Card } from 'react-bootstrap';

function Success() {
    return (
        <div style={{padding:'10px', maxWidth:'600px'}}>
        <Card>
        <Card.Header as="h5">Payment was Successful</Card.Header>
          <Card.Body style={{padding:'30px'}} className="text-center">
           <Card.Img style={{width:'100px'}} variant="top" src="success.png" />
            <Card.Text>
              Thank you for your payment.
            </Card.Text>
            </Card.Body>
        </Card>
        </div>
    );
}

export default Success;